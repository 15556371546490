import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  ChangeCurrency: "[Currency] Change",
  ChangeUserLocale: "[user.usr_locale] Change",
  updateUser: "[Update User] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  currency: 'BRL'
};

export const reducer = persistReducer(
  { storage, key: "app-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return {
          authToken: action.payload.token,
          user: action.payload.user,
		      currency: action.payload.currency
        };
      }
      case actionTypes.updateUser: {
          return {
            ...state,
            user: action.payload
          };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        return {
          ...state,
          user: action.payload
        };
      }

	  case actionTypes.ChangeCurrency: { 
        return {
          ...state,
          currency: action.payload
        };
      }

	  case actionTypes.ChangeUserLocale: { 
        return {
          ...state,
          user: {
			...state.user,
			usr_locale: action.payload
		}
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: payload => ({ type: actionTypes.Login, payload }),
  logout: () => ({ type: actionTypes.Logout }),
  changeCurrency: payload =>({type: actionTypes.ChangeCurrency, payload}),
  changeUserLocale: payload =>({type: actionTypes.ChangeUserLocale, payload}),
  requestUser: payload => ({ type: actionTypes.UserRequested, payload }),
  fulfillUser: payload => ({ type: actionTypes.UserLoaded, payload }),
  updateUser: payload => ({ type: actionTypes.updateUser, payload })
};

export function* saga() {
}

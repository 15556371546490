import React, { useEffect, useMemo, useState } from 'react';
import './sidebar.scss';
import { toAbsoluteUrl, useWindowSize } from '../../../utils/Utils';
import { needsToShowRoute, routes } from '../../../router/RouterUtils';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomDropdown } from '../custom-dropdown/CustomDropdown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { shallowEqual, useSelector } from 'react-redux';


const perfectScrollbarOptions = {
	wheelSpeed: 2,
	wheelPropagation: false
};

export const Sidebar = (props) => {
	const { permissions } = useSelector(
		({ auth }) => ({
			permissions: auth?.user?.group?.permissions ?? []
		}),
		shallowEqual
	);
	const { t } = useTranslation();
	const [routesDropdown, setRoutesDropdown] = useState(routes
		.filter(item => needsToShowRoute(item.title, permissions))
		.map(item => {
			const itemClone = { ...item };

			if (itemClone.submenu?.length > 0) {
				itemClone.submenu = itemClone.submenu.filter(submenuItem => needsToShowRoute(submenuItem.title, permissions));
			}

			return { item: itemClone, isOpen: false }
		}));

	const { width } = useWindowSize();

	const renderTopSection = () => (
		<div className='sidebar-top-section'>
			<img
				onClick={() => props.setIsSidebarOpen(false)}
				src={toAbsoluteUrl(`/images/icon-menu-open.svg`)}
				className={'sidebar-close-menu'}
				alt="Close Menu" />

			<img
				alt="Logo"
				className='sidebar-logo'
				src={toAbsoluteUrl("/images/logo-dark.svg")}
			/>
		</div>
	);

	const renderMenuSection = () => (
		<ul className='item-root-ul'>
			{routesDropdown.map((routesDropdownItem, index) => {
				const { item, isOpen } = routesDropdownItem;


				if (item.root && item.submenu?.length > 0) {

					return (
						<li key={`${item.title}-${index}`} className='item-root-li'>
							<CustomDropdown
								isOpen={isOpen}
								uniqueIdForDropdown={`${item.title}-${index}`}
								renderTitle={() => <span className='sidebar-dropdown-title'>{t(`MenuConfig.${item.title}`)}</span>}
								renderTopSection={(renderCustomIcon) => renderDropdownTopSection(item, index, renderCustomIcon)}
								renderBottomDropdownContent={() => renderDropdownContentForSubMenu(item)}
							/>
						</li>
					)
				}

				return (
					<li onClick={() => width < 975 ? props.setIsSidebarOpen(false) : void 0} key={`${item.title}-${index}`} className='item-root-li'>
						<NavLink className={'sidebar-nav-link'} to={`/${item.page}`} >
							<div className='sidebar-nav-link-fit-content'>
								<span className='sidebar-nav-link-title'>
									{t(`MenuConfig.${item.title}`)}
								</span>
								<div className="point-decoration" />
							</div>
							
						</NavLink>
					</li>
				);
			})}
		</ul>
	);

	const renderDropdownTopSection = (item, index, renderCustomIcon) => {
		return (
			<div className='sidebar-custom-dropdown-top' onClick={() => setIsOpenOnArray(index)}>
				<span className='sidebar-dropdown-title'>{t(`MenuConfig.${item.title}`)}</span>

				{renderCustomIcon()}
			</div>
		)
	};

	const setIsOpenOnArray = (index) => {
		const routesDropdownClone = [...routesDropdown];

		routesDropdown[index].isOpen = !routesDropdown[index].isOpen;

		setRoutesDropdown(routesDropdownClone);
	};

	const renderDropdownContentForSubMenu = (item) => (
		<ul className='item-submenu-ul'>
			{item.submenu.map((itemSubmenu, index) => {

				// Remove as opções do dropdown que não são desejadas
				const undesiredTitles = ['reportsCurrency', 'reportsDataPerWebsite', 'adNetworksOrders'];

				if (undesiredTitles.includes(itemSubmenu.title)) {
					return null;
				}

				return (
					<li onClick={() => width < 975 ? props.setIsSidebarOpen(false) : void 0} className='item-submenu-li' key={`item-${item.title}-${itemSubmenu.title}-${index}`}>
						<NavLink className='sidebar-nav-link' to={`/${itemSubmenu.page}`}>
							<div className='sidebar-nav-link-fit-content'>
								<span className='sidebar-nav-link-title'>
									{t(`MenuConfig.${itemSubmenu.title}`)}
								</span>

								<div className="point-decoration" />
							</div>
						</NavLink>
					</li>
				);
			})}
		</ul>
	);

	return (
		<div onClick={(e) => e.stopPropagation()} className={`sidebar-container ${props.isSidebarOpen ? 'sidebar-open' : ''}`}>
			{renderTopSection()}

			<nav className='sidebar-menu-container'>
				<PerfectScrollbar
					options={perfectScrollbarOptions}
					style={{ right: 15 }}
				>
					{renderMenuSection()}
				</PerfectScrollbar>
			</nav>
		</div>
	);
}